import { Checkbox, Form } from 'antd';
import React, { memo, useEffect, useState } from 'react';

import Name from '../../Inputs/Name';
import Password from '../../Inputs/Password';
import Phone from '../../Inputs/Phone';
import RePassword from '../../Inputs/RePassword';
import PasswordRules from '../../PasswordRules/PasswordRules';

const RegistrationForm = ({ form, isLoading, setChecked }) => {
  return (
    <>
      <Form form={form} layout='vertical' name='form_in_modal'>
        <Name />
        <Phone />

        <Password isLoading={isLoading} />

        <small className='form-text'>
          <PasswordRules />
        </small>

        <RePassword isLoading={isLoading} />
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Checkbox onChange={(v) => setChecked(v.target.checked)} />
          <a
            target='_blank'
            href='https://kassa18.ru/wp-content/uploads/2021/11/Polzovatelskoe-soglashenie.pdf'
          >
            Я соглашаюсь с условиями обработки и использования моих персональных данных.
          </a>
        </div>
      </Form>
    </>
  );
};

export default memo(RegistrationForm);

